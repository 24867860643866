.CardCreatedContainer {
  width: 85vh;
  margin-top: 20px;
}

.CardCreatedContent {
  display: flex !important;
  flex-direction: column;
  max-width: 50vh;
}

.CardCreatedButtonsContainer {
  display: flex;
}

.CardCreatedQR {
  width: 300px;
  height: 300px;
}
