.ProfileContainer {
  width: 85vh;
  margin-top: 20px;
  margin: auto;
}

.ProfileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProfileContainer ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PasswordContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}

.DeleteProfileContainer {
  display: flex;
  margin-top: 60px;
  justify-content: center;
}

.BoxForButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BoxForButton button {
  margin-top: 20px;
}

.DeleteSpan {
  margin-right: 20px;
}
