.MyTemplatesContainer {
  width: 85vh;
  margin-top: 20px;
}

.MyTemplatesContent {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
